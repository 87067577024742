import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { CityRes } from "../types";

@Injectable({
  providedIn: "root",
})
export class PlatformService {
  private citySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public city$: Observable<string> = this.citySubject.asObservable();
  
  constructor(private http: HttpClient) {}

  getAllCitiesVenue(Skip?: number, Take?: number) {
    let httpParams = new HttpParams()
      .set('Skip', Skip ?? 0)
      .set('Take', Take ?? 10)

    const endpoint = `${environment.baseUrl}${environment.actions.venues.catalogs.getAllCities.path}`;
    return this.http.get<CityRes>(endpoint, { params: httpParams });
}

  setCurrentCity(city:string){
    this.citySubject.next(city)
  }
}

