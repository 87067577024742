export const insightsEndpoints = {

  getTopVenues: {
    path: '/admin/insight/GetTopVisitedVenues',
    method: 'GET',
  },
  getFreeTrials: {
    path: '/admin/insight/FreeTrials',
    method: 'GET',
  },
  getUsers: {
    path: '/admin/insight/Users',
    method: 'GET',
  },
  getHoppyPlus: {
    path: '/admin/insight/HoppyPlus',
    method: 'GET',
  },
  getPopularTimes:{
    path: '/admin/insight/PopularTimes',
    method: 'GET',
  }
};