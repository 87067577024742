import { HttpErrorResponse, HttpInterceptorFn  } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, throwError } from "rxjs";
import { DialogService } from "../services/dialog.service";

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const dialogSvc = inject(DialogService);

  if (!req.url.includes("/Security/Login")) {
    const token = localStorage.getItem("token");
    const modifiedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return next(modifiedReq).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {

          console.error('HTTP Error Status:', err.status);
          console.error('Full Error:', err);

          if (err.status === 401) {
            console.log('Unauthorized - clearing storage and redirecting');
            localStorage.clear();
            router.navigate(['/']);
          }
          dialogSvc.errorDialog({message: err.error.message}, 4);
          
          return throwError(() => {
            console.log('Throwing error after handling');
            return err;
          });
        }
        
        console.error('Non-HTTP error:', err);
        return throwError(() => err);
      })
    );
  }

  return next(req);
};
